<template>
  <!-- 拣货任务 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="上架单号">
              <el-input v-model="form.shelfId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库单号">
              <el-input v-model="form.trayId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号">
              <el-input v-model="form.orderId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库">
              <el-select v-model="form.logicWarehouseCode" filterable clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.warehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="上架日期">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                value-format="yyyy-MM-dd "
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
            </el-form-item>
          </el-col>

        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="shelfId" label="上架单号" width="180" align="center">
        <template slot-scope="scope"><router-link :to="{ append:true, path: 'details', query: { shelfId: scope.row.shelfId,orderId:scope.row.orderId,createTime:scope.row.createTime,trayId:scope.row.trayId,tableSuffix:scope.row.tableSuffix }}" style="color:#1890ff;">{{ scope.row.shelfId }}</router-link></template>
      </el-table-column>
      <el-table-column prop="trayId" label="入库单号" width="180" align="center">
        <template slot-scope="scope"><router-link :to="{ append:true, path: '../inbound-manage/details', query: {trayId: scope.row.trayId, tableSuffix: scope.row.tableSuffix, trayData: JSON.stringify(scope.row)}}" style="color:#1890ff;">{{ scope.row.trayId }}</router-link></template>
      </el-table-column>
      <el-table-column prop="orderId" label="关联单号" width="120" align="center" />
      <el-table-column prop="transportId" label="运输单号" width="120" align="center">
        <template slot-scope="scope">{{ scope.row.transportId }}</template>
      </el-table-column>
      <el-table-column prop="logicWarehouseName" label="仓库" align="center" />
      <el-table-column prop="actualPairs" label="上架数量" align="center" />
      <el-table-column prop="createTime" label="上架时间" align="center" />
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">{{ getShelfStatusLabel(scope.row.status) }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        fixed="right"
        :label="$t('page.operate')"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 查看 -->
          <span>
            <router-link :to="{ append:true, path: 'details', query: { shelfId: scope.row.shelfId,orderId:scope.row.orderId,createTime:scope.row.createTime,trayId:scope.row.trayId,tableSuffix:scope.row.tableSuffix }}" style="color:#1890ff;"><el-button type="text" size="small">查看</el-button></router-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="update" />
  </div>
</template>
<script>
import { queryWarehouseList, pageShelfTask } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
import Paging from '@/components/Paging'
export default {
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      form: {
        shelfId: '',
        orderId: '',
        depositType: '',
        trayId: '',
        logicWarehouseCode: '',
        date: []
      },
      warehouseList: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      showRow: false, // 切换显示input
      tableLoading: false,
      tableData: [
      ]
    }
  },
  computed: {
    queryParams() {
      const [startCreateTime = '', endCreateTime = ''] = this.form.date || []
      const { shelfId, orderId, depositType, trayId, logicWarehouseCode } = this.form
      return Object.assign({}, this.pager, { startCreateTime: startCreateTime ? startCreateTime + '00:00:00' : startCreateTime, endCreateTime: endCreateTime ? endCreateTime + '23:59:59' : endCreateTime, shelfId, orderId, depositType, trayId, logicWarehouseCode })
    }
  },

  mounted() {
    this._pageShelfTask()
    this._queryWarehouseList()
  },
  methods: {
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseList = datas
    },
    queryClick() { // 点击查询获取信息
      this.pager.current = 1
      this._pageShelfTask(1)
    },
    resetClick(val) { // 点击重置清空文本框信息
      // this.form = {}
      Object.assign(this.form, this.$options.data.call(this).form)
      this._pageShelfTask(1)
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    async _pageShelfTask(flag) {
      try {
        this.flag = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await pageShelfTask(this.queryParams)
        this.pager = pager
        this.tableData = records
        this.tableLoading = false
      } finally {
        this.tableLoading = false
      }
    },
    update(val) {
      this.pager = val
      this._pageShelfTask()
    }
  }
}
</script>

